import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "container mb-10",
  style: {"margin-left":"0px"}
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-3" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "col-2" }
const _hoisted_6 = { class: "mt-5" }
const _hoisted_7 = { class: "row mt-4" }
const _hoisted_8 = { class: "col-3" }
const _hoisted_9 = { class: "col-4" }
const _hoisted_10 = { class: "col-2 p-0" }
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = {
  id: "kt_referred_users_tab_content",
  class: "tab-content"
}
const _hoisted_13 = {
  id: "kt_customer_details_invoices_1",
  class: "py-0 tab-pane fade active show",
  role: "tabpanel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "h5 text-primary" }, "Select Company", -1 /* HOISTED */)),
          _createVNode(_component_Multiselect, _mergeProps({
            class: "mt-2",
            modelValue: _ctx.companyList.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.companyList.value) = $event))
          }, _ctx.companyList, {
            onSelect: _ctx.onCompanyChange,
            placeholder: "Select the Company",
            searchable: true,
            loading: _ctx.companiesLoading
          }), null, 16 /* FULL_PROPS */, ["modelValue", "onSelect", "loading"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[9] || (_cache[9] = _createElementVNode("span", { class: "h5 text-primary" }, "Select Device", -1 /* HOISTED */)),
          _createVNode(_component_Multiselect, _mergeProps({
            class: "mt-2",
            modelValue: _ctx.deviceList.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.deviceList.value) = $event))
          }, _ctx.deviceList, {
            placeholder: "Select the Device",
            mode: "tags",
            "close-on-select": false,
            searchable: true,
            "create-option": true,
            loading: _ctx.devicesLoading
          }), null, 16 /* FULL_PROPS */, ["modelValue", "loading"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _withDirectives(_createElementVNode("input", {
              class: "form-check-input border border-primary",
              type: "checkbox",
              id: "deviceCheckBox",
              border: "",
              "border-primary": "",
              name: "SelectAllDevices",
              value: "allDevices",
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectAllDevices($event))),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.allDevicesCheckbox) = $event))
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelCheckbox, _ctx.allDevicesCheckbox]
            ]),
            _cache[10] || (_cache[10] = _createElementVNode("label", {
              class: "mx-3",
              for: "SelectAllDevices"
            }, "Select All Devices", -1 /* HOISTED */)),
            _cache[11] || (_cache[11] = _createElementVNode("br", null, null, -1 /* HOISTED */))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _cache[12] || (_cache[12] = _createElementVNode("span", { class: "h5 text-primary" }, "Select Error Type", -1 /* HOISTED */)),
          _createVNode(_component_Multiselect, _mergeProps({
            class: "mt-2",
            modelValue: _ctx.errortype.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.errortype.value) = $event))
          }, _ctx.errortype, {
            onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.changeDataByType($event))),
            placeholder: "Select the Error Type"
          }), null, 16 /* FULL_PROPS */, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[13] || (_cache[13] = _createElementVNode("span", { class: "h5 text-primary" }, "Select Date Range", -1 /* HOISTED */)),
          _createVNode(_component_el_date_picker, {
            class: "mt-2",
            modelValue: _ctx.date,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.date) = $event)),
            type: "daterange",
            "start-placeholder": "Start date",
            "end-placeholder": "End date"
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _createCommentVNode(" <div class=\"row \"> "),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            class: "btn btn-primary mx-2 topmrng btn-hover-scale",
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.fetchDeviceData && _ctx.fetchDeviceData(...args)))
          }, " Submit "),
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "btn btn-success ml-6 topmrng btn-hover-scale" }, "Export", -1 /* HOISTED */))
        ])
      ])
    ]),
    _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "h4 text-primary w-100 pb-2" }, "Devices Report", -1 /* HOISTED */)),
    _createElementVNode("div", {
      class: _normalizeClass(`card pt-2 ${_ctx.cardClasses}`)
    }, [
      _createCommentVNode("begin::Card body"),
      _createElementVNode("div", _hoisted_11, [
        _createCommentVNode("begin::Tab Content"),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_Datatable, {
              "table-header": _ctx.tableHeader,
              "table-data": _ctx.deviceTableData,
              "rows-per-page": 8,
              "enable-items-per-page-dropdown": false,
              loading: _ctx.loading
            }, _createSlots({ _: 2 /* DYNAMIC */ }, [
              _renderList(_ctx.keys, (key) => {
                return {
                  name: _ctx.slotName(key),
                  fn: _withCtx(({ row: device }) => [
                    _createTextVNode(_toDisplayString(device[key]), 1 /* TEXT */)
                  ])
                }
              })
            ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["table-header", "table-data", "loading"])
          ])
        ]),
        _createCommentVNode("end::Tab Content")
      ]),
      _createCommentVNode("end::Card body")
    ], 2 /* CLASS */),
    _createCommentVNode("end::Card")
  ], 64 /* STABLE_FRAGMENT */))
}